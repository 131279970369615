import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/core/services/common.service';
import { HttpService } from 'src/app/core/services/http.service';
import { apiUrls } from 'src/app/core/services/urlList/apiUrls';

@Component({
  selector: 'app-share-details',
  templateUrl: './share-details.component.html',
  styleUrls: ['./share-details.component.scss']
})
export class ShareDetailsComponent implements OnInit {
  email:any
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private comm: CommonService,
    private api: HttpService,
    private formBuilder: FormBuilder,
    private urlList: apiUrls,
    private dialog: MatDialogRef<ShareDetailsComponent>
  ) { }

  ngOnInit(): void {
    console.log(this.data);
    
  }
  submit(){
    let data = {
      id:this.data,
      isSendDetails:true,
      email:this.email
    }
    this.api.post(this.urlList.sendDeatils,data).subscribe((res:any)=>{
      if(res['response']['success'])
      {
        this.comm.onSuccessToaster(res['response']['message'])
        this.dialog.close("yes");
      }
    })

  }
  cancel(){
    this.dialog.close("no")
  }
}
