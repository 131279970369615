<div mat-dialog-content>
  <form>
    <div class="usr_profile_inrmain">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <div class="row form_switch">
              <div class="mt-2 form-group col-md-12">
                <div class="outer_uploadsshdf bigholderhor default_viewer">
                  <p class="heading-setting-form">Select Excel Sheet</p>

                  <div class="image_placeholder_showup_file">
                    <div class="dftlp">
                      <img src="assets/img/upload_new.png" alt="choose_img" />
                      <span>{{ name ? name : "upload File" }}</span>
                    </div>

                    <input
                      class="form-control"
                      type="file"
                      name="excelSheet"
                      (change)="fileHandler($event)"
                    />
                  </div>
                </div>
                <div
                  *ngIf="errFile || (submitted && errFile)"
                  class="invalid-feedback"
                >
                  Please select a Valid file.
                </div>
                <div *ngIf="submitted && errors" class="invalid-feedback">
                  Please select Excel file.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button class="btn btn-primary" (click)="submit()">submit</button>
</div>
