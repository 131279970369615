<h2 mat-dialog-title>Credit Amount</h2>
<form [formGroup]="walletForm">
  <mat-dialog-content>
    <input
      type="number"
      min="0"
      formControlName="creditAmount"
      class="form-control"
      placeholder="Enter wallet amount"
      appNumberOnly
      oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
    />
  </mat-dialog-content>
  <div
    *ngIf="submitted && walletForm.controls.creditAmount.errors"
    class="invalid-feedback"
  >
    <div *ngIf="walletForm.controls.creditAmount.errors.required">
      Credit Amount is required.
    </div>
    <div *ngIf="walletForm.controls.creditAmount.errors.max">
      Credit amount can not add more than 1000
    </div>
  </div>

  <div class="botton_right_buttons">
    <mat-dialog-actions>
      <button class="btn btn-primary common-btn mr-2" (click)="submit()">
        Submit
      </button>
      <button class="btn btn-danger common-btn" (click)="close()">
        Cancel
      </button>
    </mat-dialog-actions>
  </div>
</form>
