import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,

    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (state.url == '/auth/login') {
      if (
        localStorage.token == null ||
        localStorage.token == undefined ||
        localStorage.token == ''
      ) {
        return true;
      } else {
        this.router.navigate(['']);
        return false;
      }
    } else {
      if (
        localStorage.token == null ||
        localStorage.token == undefined ||
        localStorage.token == ''
      ) {
        this.router.navigate(['/auth/login']);
        return false;
      } else {
        return true;
      }
    }
  }
}
