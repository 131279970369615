import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class CommonService {
  googleForm:FormGroup
  constructor(
    private router: Router,
    private toaster: ToastrService,
    private http: HttpClient ,// private storageService: StorageService,
    private fb:FormBuilder
  ) {
    
  }

   googleAPIKey(){
    return this.googleForm = this.fb.group({
      googleAPIKey:new FormControl('AIzaSyCTrrm63uP8xIrjSNz3sVCbft2douzS1wI')
    })
  }

  moduleItem$ = new Subject<any>();
  isTypeChanged$: Subject<boolean> = new Subject<boolean>();

  logout() {
    let logout = window.confirm('Are you sure you want to logout?');
    if (logout) {
      localStorage.clear();
      this.router.navigate(['/login']);
    }
  }

  onSuccessToaster(message: string) {
    this.toaster.success(message);
  }
  getCountryCode() {
    return this.http
      .get<Response>('assets/json/countryCode.json')
      .pipe(map((response) => response));
  }

  getCountryFlag(){
    return this.http.get('assets/json/contryFlag.json').pipe(map((response) =>response)); 
  }

  onErrorToaster(message: string) {
    console.log('sfdsf', message);

    this.toaster.error(message);
  }

  convertNullToFalse(data: any) {
    for (let a in data) {
      if (data[a] == null) {
        data[a] = false;
      }
    }
    return data;
  }
  deleteEmptyKeys(data: any) {
    for (const key in data) {
      if (data[key] === '') {
        delete data[key];
      }
    }
    return data;
  }

  // Set the json data to local storage
  // setJsonValue(key: string, value: any) {
  //   this.storageService.secureStorage.setItem(key, value);
  // }

  // // Get the json value from local storage
  // getJsonValue(key: string) {
  //   return this.storageService.secureStorage.getItem(key);
  // }

  convertNullToFalseInFormGroup(formGroup: any) {
    for (let key in formGroup.controls) {
      if (formGroup.controls[key].value == null) {
        formGroup.controls[key].setValue(false);
      }
    }
    return formGroup;
  }

  setDateToField(value: any) {
    let date = new Date(value);
    let dd: any = date.getDate();
    let mm: any = date.getMonth() + 1;
    let yy: any = date.getFullYear();
    if (mm < 10) {
      mm = '0' + mm;
    }
    if (dd < 10) {
      dd = '0' + dd;
    }
    return yy + '-' + mm + '-' + dd;
  }
  convertTimeToUTC(value: any) {
    let date = new Date('01/01/2007 ' + value);
    let utcHours: any = date.getUTCHours();
    let utcMin: any = date.getUTCMinutes();
    if (JSON.stringify(utcHours).length <= 1) {
      utcHours = '0' + utcHours;
    }
    if (JSON.stringify(utcMin).length <= 1) {
      utcMin = '0' + utcMin;
    }
    return utcHours + ':' + utcMin;
  }
}
