import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private _http: HttpClient) { }

  // Post Service
  post(url: string, postData: any) {
    return this._http.post<any>(`${environment.baseUrl}${url}`, postData).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Get Service
  get(url: string) {
    return this._http.get<any>(`${environment.baseUrl}${url}`).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Get Service
  getWithQuery(url: string, query: string) {
    return this._http.get<any>(`${environment.baseUrl}${url}?` + query).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Get Service
  getWithQueryParams(url: string, params: any) {
    return this._http
      .get<any>(`${environment.baseUrl}${url}`, {
        params: params,
      })
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  // Put Service
  put(url: string, putData: any) {
    return this._http.put<any>(`${environment.baseUrl}${url}`, putData).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // put with query
  putWithQuery(url: string, params: any, data: any) {
    return this._http.put<any>(
      `${environment.baseUrl}${url}?${params}`, data
    )
  }

  // Patch Service
  patch(url: string, putData: any) {
    return this._http.patch<any>(`${environment.baseUrl}${url}`, putData).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Delete Service
  delete(url: string) {
    return this._http.delete<any>(`${environment.baseUrl}` + url).pipe(
      map((data: any) => {
        return data;
      })
    );
  }
  deleteWithQuery(url: string, query: string) {
    return this._http.delete<any>(`${environment.baseUrl}${url}?` + query).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Upload Image Service
  uploadMedia(url: any, formData: any) {
    return this._http.post<any>(`${environment.baseUrl}` + url, formData).pipe(
      map((data: any) => {
        return data;
      })
    );
  }
}
