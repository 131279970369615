<header>
  <div class="">
    <div class="navbar navbar-expand-lg navbar-light bg-light static-top">
      <a class="navbar-brand" href="#">
        <figure>
          <img src="assets/apptunix.png" alt=""/>
        </figure>
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <i class="fas fa-bars"></i>
       </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto w-100 justify-content-end">
          <li class="nav-item tgl">
            <mat-select (selectionChange)="changeViewType($event)" [(ngModel)]="singleType">
              <mat-option [value]="item.value" *ngFor="let item of type">{{
                item?.key.split("_")[1]
                }}</mat-option>
            </mat-select>
          </li>
            <li class="nav-item" *ngFor="let data of moduleData;let i=index">
              <a class="nav-link" (click)="redirect(data)">
                <button mat-stroked-button>
                  {{data?.name}}
                </button>
              </a>
            </li>
          <li class="nav-item nav_links">
            <a class="nav-link" href="#">Profile</a>
          </li>
          <li class="nav-item nav_links cursor-pointer">
            <a class="nav-link" (click)="logout()">Logout</a>
          </li>
        </ul>
      </div>

    </div>

  </div>
</header>
