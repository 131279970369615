import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { tap, catchError, finalize } from 'rxjs/operators';
import { NgxSpinnerService } from "ngx-spinner";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  ErrorMessage: string = '';
  constructor(
    private router: Router,
    private toaster: ToastrService ,
    private _ngxService: NgxSpinnerService,
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this._ngxService.show();
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status == 403) {
        }
        if (error.status == 401) {
          this.logOut();
          // 401 handled in auth.interceptor
          // this.commonService.presentsToast('error','top-end','you are logout for security purpose.');
        }
        return throwError(error);
      }),
      finalize(() => {
        this._ngxService.hide();
      }),
      tap(
        (event) => {
          var eventRes = JSON.parse(JSON.stringify(event));
          if (eventRes.body) {
            if (
              eventRes.body &&
              eventRes.body.response &&
              !eventRes.body.response.success
            ) {
              this.toaster.error(eventRes.body.response.message);
            }
          }
        },
        (error) => {
          switch (error.status) {
            case 401:
              /***  Auto LogOut if Api response 401 ** */
              this.toaster.error(
                'You have been loggedout for security purpose.'
              );
              // this.logOut();
              break;
            case 500:
              /*** If api does not respond  ** */
              this.toaster.error(error.error.message);
              break;
            case 404:
              /*** If api does not respond  ** */
              // this.toaster.error(error.error.message);
              break;
            case 400:
              /*** If api does not respond  ** */
              this.toaster.error(error.error.response.message);
              break;
            default:
              if (error.error) {
                error.error.message && this.toaster.error(error.error.message);
                // this.commonService.presentsToast('error','top-end',error.error.message);
              } else {
                error.message && this.toaster.error(error.message);
                // this.commonService.presentsToast('error','top-end',error.message);
              }
          }
        }
      )
    );
  }

  logOut() {
    localStorage.clear();
    this.router.navigate(['/auth/login']);
  }
}
