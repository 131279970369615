import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { CommonService } from 'src/app/core/services/common.service';
import { HttpService } from 'src/app/core/services/http.service';
import { apiUrls } from 'src/app/core/services/urlList/apiUrls';

@Component({
  selector: 'app-sub-admin-password',
  templateUrl: './sub-admin-password.component.html',
  styleUrls: ['./sub-admin-password.component.scss']
})
export class SubAdminPasswordComponent implements OnInit,OnDestroy {
  parentData: any[];
  id: any;
  password: any;
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor( private dialog: MatDialogRef<SubAdminPasswordComponent>,
    private http: HttpService,
    private api: apiUrls,
    private _commonService: CommonService) { }

  ngOnInit(): void {
    console.log(this.parentData,'parent');
    
    if (this.parentData) this.id = this.parentData;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  btn_updatePassword(){
    let payloadData={
      password:this.password
    };
    this.http
        .put(this.api.subAdmin +'?id='+this.id, payloadData)
        .subscribe((res) => {
          if (res && res['response']['status'] == 200) {
            this.dialog.close();
            this._commonService.onSuccessToaster(res['response']['message']);
          }
        });
  }
}
