import { Component, OnInit } from '@angular/core';
import { CommonService } from './core/services/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'great-admin-panel';
  script = document.createElement("script");
  googleAPiKey: any;
  constructor(public _coomonService: CommonService){

  }
  ngOnInit() {
    this.googleAPiKey= this._coomonService.googleAPIKey().controls.googleAPIKey.value  
    console.log(this.googleAPiKey,'this.googleAPiKey');   
    this.setGoogleAPiKeyIndex(); 
  }
  setGoogleAPiKeyIndex(){
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://maps.googleapis.com/maps/api/js?key=${this.googleAPiKey}&libraries=places,drawing,geometry,visualization&language=en&v=3.exp` ;
    script.id = 'id';
    document.head.appendChild(script);
  }
 
}
