import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from 'src/app/core/services/common.service';
import { HttpService } from 'src/app/core/services/http.service';
import { apiUrls } from 'src/app/core/services/urlList/apiUrls';

@Component({
  selector: 'app-addmodule',
  templateUrl: './addmodule.component.html',
  styleUrls: ['./addmodule.component.scss'],
})
export class AddmoduleComponent implements OnInit, OnDestroy {
  dialogType: string;
  addModule: any;
  parentData: Array<any>;
  subModuleId: string;
  subModuleName: string;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private dialog: MatDialogRef<AddmoduleComponent>,
    private http: HttpService,
    private api: apiUrls,
    private _commonService: CommonService
  ) {}

  ngOnInit(): void {
    if (this.parentData) this.subModuleId = this.parentData[0]._id;
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  addModuleSubmit() {
    if (this.dialogType === 'module') {
      this.http
        .post(this.api.parentModule, { moduleName: this.addModule })
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          console.log(res);
          if (res['response']['success']) {
            this._commonService.onSuccessToaster(res['response']['message']);
            this.dialog.close();
          }
        });
    } else {
      let params = {
        moduleName: this.subModuleName,
        isSubModule: true,
        parentModule: this.subModuleId,
      };
      this.http
        .post(this.api.parentModule, params)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          console.log(res);
          if (res['response']['success']) {
            this._commonService.onSuccessToaster(res['response']['message']);
            this.dialog.close();
          }
        });
    }
  }
}
