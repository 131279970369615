import { BrowserModule } from '@angular/platform-browser';
import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { MainComponent } from './page-layout/main/main.component';
import { AuthComponent } from './page-layout/auth/auth.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './shared/Material/material.module';
import {  ToastrModule } from 'ngx-toastr';
import { HttpService } from './core/services/http.service';
import { apiUrls } from './core/services/urlList/apiUrls';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './core/services/intercepter/get-interceptor.interceptor';
import { config } from 'rxjs';
import { NgxSpinnerModule } from "ngx-spinner";
import { TokenInterceptor } from './core/services/intercepter/set-interceptor.interceptor';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgxEchartsModule } from 'ngx-echarts';
import { GraphModule } from './graph/graph.module';
import { MatDialogRef } from '@angular/material/dialog';
import { AddCreditComponent } from './pages/client/add-credit/add-credit.component';
import { AddmoduleComponent } from './dialog/addmodule/addmodule.component';
import { SubmoduleComponent } from './dialog/submodule/submodule.component';
import { AgmCoreModule } from '@agm/core';
import { Constant } from './core/services/constant';
import { SubAdminPasswordComponent } from './dialog/sub-admin-password/sub-admin-password.component';
import { ShareDetailsComponent } from './pages/client/share-details/share-details.component';
import { environment } from 'src/environments/environment';


@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    MainComponent,
    AddmoduleComponent,
    SubmoduleComponent,
    SubAdminPasswordComponent,

  ],
  imports: [
    SharedModule,
    MaterialModule,
    BrowserModule,
    AppRoutingModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      maxOpened: 1,
      preventDuplicates: true,
    }),
    HttpClientModule,
    UiSwitchModule.forRoot({}),
    // AgmCoreModule.forRoot({
    //   apiKey: ,
    //   libraries: ['geometry', 'places', 'drawing'],
    // }),

    // NgxEchartsModule.forRoot({
    //   echarts: () => import('echarts'),
    // }),
    // NgxEchartsModule.forRoot({ echarts: { init: echarts.init } })
  ],
  providers: [
    HttpService,
    Constant,
    apiUrls,
    { provide: '', useValue: config },

    // {
    //   provide: TOAST_CONFIG,
    //   useValue: {
    //     default: DefaultGlobalConfig,
    //     config,
    //   },
    // },
    // {
    //   provide: MatDialogRef,
    //   useValue: {},
    // },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  entryComponents: [AddCreditComponent, AddmoduleComponent, SubmoduleComponent, SubAdminPasswordComponent,ShareDetailsComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule { }
