import { Injectable } from "@angular/core";
import { CommonService } from "./common.service";
@Injectable({
    providedIn: 'root'
})
export class Constant {
    MODULES: any= [
        {

            edit: true,
            view: true,
            delete: true,
            route: '/dashboard',
            moduleCode: 1,
            name: 'Dashboard',
        },
        {
            edit: true,
            view: true,
            delete: true,
            route: '/subscription/list',
            moduleCode: 2,
            name: 'Subscription Plan',
        },
        {
            edit: true,
            view: true,
            delete: true,
            route: '/client/list',
            moduleCode: 3,
            name: 'Projects',
        },
     
        {
            edit: true,
            view: true,
            delete: true,
            route: '/client/onboard-list',
            moduleCode: 4,
            name: 'Leads',
        },
        {
            edit: true,
            view: true,
            delete: true,
            route: '/module/list',
            moduleCode: 5,
            name: 'Modules',
        },
        {
            edit: true,
            view: true,
            delete: true,
            route: '/extension/list',
            moduleCode: 6,
            name: 'Extension',
        },
        {
            edit: true,
            view: true,
            delete: true,
            route: '/feature/list',
            moduleCode: 7,
            name: 'Features',
        },
        {
            edit: true,
            view: true,
            delete: true,
            route: '/sub-Admin/list',
            moduleCode: 8,
            name: 'Sub-Admin',
        },
    ]


    Sales_Modules:any=[
        {
            edit: true,
            view: true,
            delete: true,
            route: '/dashboard',
            moduleCode: 1,
            name: 'Dashboard',
        },
        {
            edit: true,
            view: true,
            delete: true,
            route: '/client/list',
            moduleCode: 3,
            name: 'Client List',
        },
    ]
}