import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { CommonService } from '../common.service';
import { stringify } from '@angular/compiler/src/util';
// import { NgxSpinnerService } from 'ngx-spinner';
@Injectable({
  providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    // private spinner: NgxSpinnerService
    private common: CommonService
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const clonedReq = this.handleRequest(req);
    // this.spinner.show();
    return next.handle(clonedReq);
  }
  handleRequest(req: HttpRequest<any>) {
    let token: any = 'NA';
    let viewType: any = 'NA';

    if (localStorage.getItem('token')) {
      token = localStorage.getItem('token');
      // token = this.common.getJsonValue('token');
    }
    // if (typeof token === 'object') {
    //   token = JSON.parse(localStorage.getItem('token'));

    // }
    if (localStorage.setViewType) {
      viewType = JSON.parse(localStorage.getItem('setViewType') || '');
      console.log(viewType);
    }
    let authReq;
    authReq = req.clone({
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        viewtype: viewType,
        Authorization: `${token}`,
      }),
    });
    if (
      (req.method.toLowerCase() == 'post' ||
        req.method.toLowerCase() == 'put') &&
      req.body instanceof FormData
    ) {
      authReq = req.clone({
        headers: new HttpHeaders({
          Authorization: `${token}`,
        }),
      });
    }
    return authReq;
  }
}
