<div mat-dialog-content *ngIf="dialogType === 'module'">
  <p>Add Module Type</p>
  <mat-form-field appearance="fill">
    <mat-label>Enter Add Module Type</mat-label>
    <input matInput [(ngModel)]="addModule" />
  </mat-form-field>
</div>
<div mat-dialog-content *ngIf="dialogType === 'submodule'">
  <p>Sub Module</p>
  <mat-select class="form-control" [(ngModel)]="subModuleId">
    <mat-option value="" selected disabled hidden>
      Select Module Type
    </mat-option>
    <mat-option *ngFor="let item of parentData" [value]="item._id">
      {{ item?.moduleName }}
    </mat-option>
  </mat-select>
  <mat-form-field appearance="fill">
    <mat-label>Enter Sub Module</mat-label>
    <input matInput [(ngModel)]="subModuleName" />
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button class="btn btn-primary" (click)="addModuleSubmit()">Submit</button>
</div>
