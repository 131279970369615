<div class="canle_res">
    <h2>Share Project Details</h2>
    <button mat-button mat-dialog-close class="clos_bt"> <mat-icon>close</mat-icon></button>
    <form action="">
        <div class="form-group">

          <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email"[ngModelOptions]="{standalone: true}" [(ngModel)]="email">
        </div>
        <div class="btn_sb">
        <button class="btn btn-primary nw_cmmn_btn" (click)="submit()">Submit</button>

      </div>
    </form>
</div>
