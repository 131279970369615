import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpService } from 'src/app/core/services/http.service';
import { apiUrls } from 'src/app/core/services/urlList/apiUrls';

@Component({
  selector: 'app-submodule',
  templateUrl: './submodule.component.html',
  styleUrls: ['./submodule.component.scss'],
})
export class SubmoduleComponent implements OnInit {
  moduleId: any = false;
  File: any;
  errFile: boolean = false;
  name: any;
  uploaded: boolean = false;
  errors: boolean = true;
  submitted: boolean = false;
  moduleSheet: string;
  constructor(
    private dialog: MatDialogRef<SubmoduleComponent>,
    private api: HttpService,
    private apiList: apiUrls
  ) {}
  parentId: any;

  ngOnInit(): void {}

  async uploadImage(file: any) {
    let formData = new FormData();
    formData.append('file', file);
    let res = await this.api
      .post(this.apiList.uploadImages, formData)
      .toPromise();
    return res['data'];
  }
  async fileHandler(event: any) {
    this.File = event.target.files[0];
    if (
      this.File.type !==
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      this.errFile = true;
    } else {
      this.moduleSheet = await this.uploadImage(this.File);
      this.name = this.File['name'];

      this.errFile = false;
    }
    this.uploaded = true;
    this.errors = this.File ? false : true;
  }
  submit() {
    this.submitted = true;
    let data = {
      moduleId: this.moduleId,
      product: this.moduleSheet,
    };
    this.api.post(this.apiList.add, data).subscribe((res) => {
      console.log(res);
    });
  }
}
