import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/core/services/common.service';
import { HttpService } from 'src/app/core/services/http.service';
import { apiUrls } from 'src/app/core/services/urlList/apiUrls';

@Component({
  selector: 'app-add-credit',
  templateUrl: './add-credit.component.html',
  styleUrls: ['./add-credit.component.scss']
})
export class AddCreditComponent implements OnInit {
  walletForm: any;
  submitted: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private comm: CommonService,
    private api: HttpService,
    private formBuilder: FormBuilder,
    private urlList: apiUrls,
    private dialog: MatDialogRef<AddCreditComponent>
  ) { }

  ngOnInit(): void {
    this.createForm();
  }
  createForm() {
    this.walletForm = this.formBuilder.group({
      creditAmount: new FormControl("10", [Validators.required, Validators.max(1000)]),
    });
  }
  submit() {
    let formData = this.walletForm.controls;
    let data = {
      creditAmount: formData.creditAmount.value,
      appId: this.data
    }
    this.api.post(this.urlList.creditAmount, data).subscribe((res: any) => {
      if (res['response']['success']) {
        this.comm.onSuccessToaster((res['response']['message']))
        this.dialog.close(data)
      }
    })
  }

  close() {
    this.dialog.close("no");
  }

}
