import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CommonService } from 'src/app/core/services/common.service';
import { Constant } from 'src/app/core/services/constant';
import { HttpService } from 'src/app/core/services/http.service';
import { apiUrls } from 'src/app/core/services/urlList/apiUrls';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  viewType: any = [];
  isActive1: boolean = false;
  isActive2: boolean = false;
  isActive3: boolean = false;
  isActive4: boolean = false;
  isActive5: boolean = false;
  issetLocal$: Subject<any> = new Subject<any>();
  destroy$: Subject<boolean> = new Subject<boolean>();
  singleType: string;
  type: Array<any> = [];
  moduleData: any;
  role:string;
  constructor(
    private router: Router,
    private comm: CommonService,
    private http: HttpService,
    private api: apiUrls,
    private constant: Constant,
    private route: ActivatedRoute
  ) {

    this.role= localStorage.getItem('role') || '';
    switch(this.role){
      case 'sales':
        this.moduleData = [...this.constant.Sales_Modules];
        break;
      default :
      this.moduleData = [...this.constant.MODULES];
    }     
  }

  ngOnInit(): void {
    this.issetLocal$.pipe(take(1)).subscribe((res) => {
      if (res) {
        if (localStorage.viewType)
          this.type = JSON.parse(localStorage.getItem('viewType') || '');
        this.singleType = this.type[0].value;
      }
    });
      this.getViewType();
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  changeViewType(type: any) {
    if (type) {
      this.router.navigate(['/dashboard']);
      localStorage.setItem('setViewType', JSON.stringify(type.value));
      this.comm.isTypeChanged$.next(true);
    }
  }

  redirect(route:any){
    this.router.navigate([route.route], route.isQueryParams?{queryParams:{role:this.role}}:{})
  }

  logout() {
    Swal.fire({
      title: 'Are you sure?',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result: any) => {
      if (result.value) {
        localStorage.clear();
        this.router.navigate(['/auth/login']);
      }
    });
  }

  getViewType() {
    this.http
      .get(this.api.getView)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        console.log('res: ', res);
        if (res['response']['success']) {
          this.viewType = res.data;
          localStorage.setItem('viewType', JSON.stringify(this.viewType));
          localStorage.setItem(
            'setViewType',
            JSON.stringify(this.viewType[0].value)
          );
          if (localStorage.setViewType) {
            this.issetLocal$.next(true);
          }
          this.type = JSON.parse(localStorage.getItem('viewType') || '');
        }
      });
  }


}
